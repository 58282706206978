<template>
    <div class="page-wrap">
        <div class="left d-none d-md-block"></div>

        <div class="right">
            <div class="session-form-hold text-center">
                <div class="logo-wrapper">
                    <img height="30" src="@/assets/images/logo.svg" alt="" />
                </div>

                <base-card style="box-shadow: none !important;">
                    <v-progress-linear :active="loading" :indeterminate="loading" absolute top
                        color="primary"></v-progress-linear>
                    <v-card-text class="text-center">
                        <v-form @submit.prevent="formSubmit">
                            <v-text-field label="Email" v-model="email" :rules="emailRules" :disabled="isLoading"
                                required />

                            <v-text-field :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'" name="input-10-2" label="Password" :counter="10"
                                :rules="passwordRules" :disabled="isLoading" v-model="password"
                                @click:append="showPassword = !showPassword"></v-text-field>

                            <v-text-field :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPasswordConfirmation ? 'text' : 'password'" name="input-10-2"
                                label="Password Confirmation" :counter="10" :rules="passwordConfirmationRules"
                                :disabled="isLoading" v-model="passwordConfirmation"
                                @click:append="showPasswordConfirmation = !showPasswordConfirmation"></v-text-field>

                            <v-btn :loading="isLoading" class="mb-5 mt-7 sign-in-btn" @click="formSubmit" large block
                                dark>Reset
                                Password</v-btn>

                            <!-- <div class="d-flex justify-center flex-wrap">
                <v-btn @click.prevent="$router.push('/app/sessions/sign-in')" text small color="" class="mb-2">
                  < Back to login</v-btn>
              </div> -->
                        </v-form>
                    </v-card-text>
                </base-card>
            </div>
        </div>
    </div>
</template>
<script>
import users from '../../../api/users';
import { mapGetters, mapActions } from "vuex";
const _ = require('lodash');
export default {
    name: "login",
    metaInfo: {
        title: "Sign In",
    },
    data() {
        return {
            showPassword: false,
            showPasswordConfirmation: false,
            email: "",
            password: "",
            passwordConfirmation: "",
            loading: false,
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            passwordRules: [
                (v) => !!v || "Password is required"
            ],
            passwordConfirmationRules: [
                (v) => !!v || "Password confirmation is required"
            ],
        };
    },
    created() { },
    computed: {
        ...mapGetters("auth", {
            isLoading: "isLoading"
        }),
    },
    methods: {
        ...mapActions("auth", {
            attemptLogin: "attemptLogin"
        }),

        formSubmit() {
            let vm = this;
            if (!vm.email || vm.email.trim().length == 0) {
                vm.$toast.info("Please provide an email address");
                return false;
            }
            if (!vm.password || vm.password.trim().length == 0) {
                vm.$toast.info("Please provide a new password");
                return false;
            }
            if (!vm.passwordConfirmation || vm.passwordConfirmation.trim().length == 0) {
                vm.$toast.info("Please confirm your password");
                return false;
            }
            if (vm.password !== vm.passwordConfirmation) {
                vm.$toast.info("Password and password confirmation don't match");
                return false;
            }
            users.resetPassword(
                vm.email,
                vm.password,
                vm.passwordConfirmation,
                vm.$route.query.token
            ).then(r => {
                vm.$toast.success('Password reset successfully');
                vm.$router.push('/app/sessions/sign-in');
            }).catch(e => {
                console.log(e);
                let errors;
                if (typeof e.response.data === 'object') {
                    errors = _.flatten(_.toArray(e.response.data.errors));
                } else {
                    errors = ['Something went wrong. Please try again.'];
                }
                vm.$toast.error(errors.length ? _.first(errors) : e);
            });
            // this.attemptLogin({ email: this.email, password: this.password });
        }
    },
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
    // text-align: center;
    margin-bottom: 30px;
}

.page-wrap {
    background-color: #f1f1f1 !important;
    display: flex;
    align-items: center;
    // padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;

    .left {
        flex-grow: 1;
        width: 50%;
        height: 100%;
        display: block;
        background-color: #F7E4EA !important;
        background-image: url('/home/reset.jpeg');
        ;
        background-size: cover;
        background-position: bottom center;
    }

    .right {
        flex-grow: 1;

        padding: 20px;

        // background-color: #F7E4EA !important;
        background-image: url('/home/maia-portal.jpg') !important;
        background-size: cover;
        background-position: bottom;

        @media screen and (min-width: 960px) {
            width: 50%;
            padding: 100px;
            background: #fff !important;
        }

        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sign-in-btn {
            // background: #F7E4EA;
        }
    }
}
</style>
